export enum ActionType {
    ADD_SONG_FILE = "ADD_SONG_FILE",
    REMOVE_SONG_FILE = "REMOVE_SONG_FILE",
    ADD_ARTWORK_FILE = "ADD_ARTWORK_FILE",
    REMOVE_ARTWORK_FILE = "REMOVE_ARTWORK_FILE",
    SET_IS_UPLOADING = "SET_IS_UPLOADING",
    CHANGE_SONGS_PAGE = "CHANGE_SONGS_PAGE",
    PLAY_SONG = "PLAY_SONG",
    PAUSE_SONG = "PAUSE_SONG",
    SKIP_FORWARD_SONG = "SKIP_FORWARD_SONG",
    SKIP_BACK_SONG = "SKIP_BACK_SONG",
    SET_SONG_DURATION = "SET_SONG_DURATION",
    SET_SONG_CURR_TIME = "SET_SONG_CURR_TIME",
    SET_SONG_LOADING = "SET_SONG_LOADING",
    SET_BUFFERED_LENGTH = "SET_BUFFERED_LENGTH",
    SET_SONG_SEEK_TIME = "SET_SONG_SEEK_TIME"
}